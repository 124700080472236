import {makeAutoObservable} from "mobx";
import * as Api from "api/api";
import ImgEnum from "enum/ImgEnum";
import axios from 'axios';
import {fetchDecorateConfig} from "api/api";

class App {
    logo = null;
    app = null;
    home = null;
    contact = null;
    website = null;
    recordUrl = "";
    recordTime = "";
    decorateConfig = null;


     fetchContact() {
        axios.all([Api.contactInfo(), Api.contact()])
            .then(axios.spread(({name, phone}, {show}) => {
                this.contact = {
                    name,
                    phone,
                    show
                }
            }))
    }


 /*   async fetchLogo() {
        try {
            const {info} = await Api.imgInfo({type: ImgEnum.INDEXLOGO});

            this.logo = info;
        } catch (error) {
        }
    }*/


    async fetchApp() {
        try {
            const {info: app} = await Api.app();
            this.app = app;
        } catch (error) {
        }
    }


    async fetchHome() {
        try {
            const {info: home} = await Api.home();
            this.home = home;
        } catch (error) {
        }
    }


    async fetchWebsite() {
        try {
            const {info: website} = await Api.website();
            this.website = website;
        } catch (error) {
        }
    }

     fetchRecordUrl() {
        Api.recordUrl().then(recordUrl => {
            this.recordUrl = recordUrl;
        })
    }

     fetchRecordTime() {
        Api.recordTime().then(recordTime => {
            this.recordTime = recordTime;
        })
    }

    fetchDecorateConfig(){
        Api.fetchDecorateConfig().then(data=>{
            this.decorateConfig = data;
        })
    }

     fetchData() {
        try {
            // this.fetchLogo();
            this.fetchApp();
            this.fetchHome();
            this.fetchContact();
            this.fetchWebsite();
            this.fetchRecordUrl();
            this.fetchRecordTime();
            this.fetchDecorateConfig();
        } catch (error) {
        }
    }


    constructor() {
         makeAutoObservable(this)
    }
}

const app = new App();

export default app;
