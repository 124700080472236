import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

export async function fetchDecorateConfig() {
    return http({
        method: Method.GET,
        baseURL: Global.BASE_URL + '/api/decorate-config',
    }).then(res => res.data)
}